import { shouldShowCheckoutBtnSpinner } from '../../../Utils/Utils';
import { showAtcModal } from './AddToCartModalHelper';
import { AdobeAnalyticsUtil } from './AdobeAnalytics/AdobeAnalyticsUtil';
import { addToCartByCartLine, addToCartSources } from '/Foundation/CDP/code/Utilities/cdpHelper';

const addToCartModalElement = document.getElementById('addToCartModal');
const addToCartModalBackdropElement = document.getElementById('addToCartModalBackdrop');
let isRequestInProgress = false;
const setupButton = (button) => {
  button.addEventListener('click', async (event) => {
    if (isRequestInProgress) return;
    const wrapper = button.closest('.product-add-to-cart');
    const productId = wrapper.querySelector('input.product-id').value;
    const quantity = wrapper.querySelector('input.quantity').value;
    const solution = wrapper.querySelector('input.product-ids-for-solution').value;

    var category = getProductCategory(button);

    const products = [];

    if (solution) {
      const ids = solution.split(',');

      for (let i = 0; i < ids.length; i++) {
        products.push({
          productId: ids[i],
          category: category,
        });
      }
    } else {
      products.push({
        productId: productId,
        category: category,
      });
    }
    let cart = await updateCart(products, quantity, button);

    if (addToCartModal) {
      populateAddToCartModal(button, cart);
    }
  });
};
const emptyCartModalProductsHtml = document.getElementById('addToCartProducts')?.innerHTML;

const populateAddToCartModal = (button, cart) => {
  let cartModalProductsElement = document.getElementById('addToCartProducts');
  if (cartModalProductsElement) cartModalProductsElement.innerHTML = emptyCartModalProductsHtml;

  const wrapper = button.closest('.product-add-to-cart');
  const productImage = wrapper.querySelector('.atc-product-image-url').value;
  const productName = wrapper.querySelector('.atc-product-name').value;
  const webtitle2 = wrapper.querySelector('.atc-product-name2').value;
  let productPrice = wrapper.querySelector('.atc-product-price').value;
  let originalPriceFormatted = wrapper.querySelector('.atc-original-price-formatted').value;
  const cartUrl = wrapper.querySelector('.atc-cart-url').value;
  const checkoutUrl = wrapper.querySelector('.atc-checkout-url').value;

  const lblProductName = addToCartModalElement.querySelector('.product-detail-modal-product-name');
  const lblWebTitle2 = addToCartModalElement.querySelector('.product-detail-modal-webtitle2');
  const lblPrice = addToCartModalElement.querySelector('.cart-modal-price');
  const lblOriginalPrice = addToCartModalElement.querySelector('.cart-modal-original-price');
  const imgProductImage = addToCartModalElement.querySelector('.product-detail-modal-image');
  const btnGotoCart = addToCartModalElement.querySelector('.btn-goto-cart');
  const btnGotoCheckout = addToCartModalElement.querySelector('.btn-goto-checkout');
  const btnClose = addToCartModalElement.querySelector('.btn-modal-close');
  if (button.classList.contains('dbg_cart_btn')) {
    btnGotoCart.classList.add('d-none');
    btnGotoCheckout.classList.add('d-none');
    btnClose.classList.remove('d-none');

    const closeButton = addToCartModalElement.querySelector('.hide-add-to-cart-modal');
    if (closeButton)
      btnClose.addEventListener('click', () => {
        closeButton.click();
      });

    const pli = wrapper.closest('.product-list-item');
    const pliImg = pli.querySelector('.product-link .img-container img:not(.d-none)');

    imgProductImage.src = pliImg.src;
  } else {
    imgProductImage.src = productImage;
  }

  if (imgProductImage.src === window.location.href) imgProductImage.classList.add('d-none');

  lblProductName.innerHTML = productName;
  lblWebTitle2.innerHTML = webtitle2;
  lblPrice.innerHTML = productPrice;
  lblOriginalPrice.innerHTML = originalPriceFormatted;
  btnGotoCart.href = cartUrl;
  imgProductImage.alt = productName;

  if (productPrice === originalPriceFormatted) {
    lblOriginalPrice.classList.add('d-none');
  }

  btnGotoCheckout.addEventListener('click', () => {
    if (shouldShowCheckoutBtnSpinner()) {
      btnGotoCheckout.querySelector('.spinner').classList.remove('d-none');
    }

    let products = [];
    cart?.Lines?.map((line) => {
      products.push({
        id: line.SKUNumber,
        name: line.Title,
        subcategory: line.SubcategoryAnalytics,
        category: line.CategoryAnalytics,
        over_category: line.OvercategoryAnalytics,
        colour: line.Color,
        stock_status: line.StockStatusAnalytics,
      });
    });
    const adobeAnalyticsData = {
      cart_type: 'minicart',
      products: products,
    };
    AdobeAnalyticsUtil.DataLayer.TrackEvent('initiate_checkout', adobeAnalyticsData);
    window.location.href = checkoutUrl;
  });
};

const updateCart = async (products, quantity, button) => {
  if (button.classList.contains('dbg_cart_btn')) {
    if (addToCartModalElement) showAtcModal(addToCartModalElement, addToCartModalBackdropElement);
    return;
  }
  isRequestInProgress = true;
  const displayLoading = button.querySelector('.add-to-cart-loader');
  const ADD_CART_LINE = '/api/sitecore/cart/addcartline';
  if (displayLoading) {
    displayLoading.classList.remove('d-none');
  }

  let cart;
  await fetch(ADD_CART_LINE, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      productsData: products,
      quantity: quantity,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (displayLoading) {
        displayLoading.classList.add('d-none');
      }
      if (addToCartModalElement) {
        showAtcModal(addToCartModalElement, addToCartModalBackdropElement);
      }

      if (typeof mootrack !== 'undefined') {
        const wrapper = button.closest('.product-add-to-cart');
        const productId = wrapper.querySelector('.atc-product-erpoutdoor').value;
        const productpageUrl = window.location.origin + wrapper.querySelector('.atc-product-page-url').value;
        const productPriceNoCurrency = wrapper.querySelector('.atc-product-price-nocurrency').value;
        const productName = wrapper.querySelector('.atc-product-name').value;
        const productImageUrl = wrapper.querySelector('.atc-product-image-url-webp').value;

        const productName2 = wrapper.querySelector('.atc-product-name2').value;

        var extraProps = { language: window._THULEDATA.SitecoreLanguage, itemName2: productName2 };
        mootrack(
          'trackAddToOrder',
          productId,
          productPriceNoCurrency,
          productpageUrl,
          1,
          productPriceNoCurrency,
          productName,
          productImageUrl,
          extraProps
        );
      }

      const productContainer = button.closest('.product-add-to-cart');
      const analyticsData = productContainer?.querySelector('.analyticsData')?.dataset;

      products.forEach((product) => {
        trackToCDP(data?.Cart, product.productId, analyticsData);
      });

      document.querySelectorAll('.cart-counter').forEach((currentNode) => {
        currentNode.innerHTML = data.TotalQuantity;
        currentNode.classList.toggle('d-none', data.TotalQuantity === 0);
      });

      document.querySelector('.product-detail-modal-cart-counter').innerHTML = data.TotalQuantity;
      cart = data?.Cart;
    })
    .catch((error) => {
      if (displayLoading) {
        displayLoading.classList.add('d-none');
      }
      console.error('Error:', error);
    })
    .finally(() => {
      isRequestInProgress = false;
    });
  return cart;
};

const trackToCDP = (cart, productId, analyticsData) => {
  if (typeof addToCartByCartLine === 'undefined' || !cart?.Lines || !productId) {
    return;
  }

  var source = addToCartSources.productDetailPage;
  if (analyticsData.isbundle === 'True') {
    source = addToCartSources.bundleProductDetailPage;
  }

  addToCartByCartLine(cart, productId, source);
};

const getProductCategory = (button) => {
  let url = button.closest('article.product-list-item')?.querySelector('.product-link').href;

  if (!url)
    url = button
      .closest('.productSliderItem__contentContainer')
      ?.querySelector('.tag-manager-product-slider-item').href;

  if (!url) url = window.location.href;
  try {
    let category = google.tagmanager.parseCategoryFromUrl(url);
    return category;
  } catch (error) {}
};

document.addEventListener('DOMContentLoaded', function () {
  if (_THULEDATA.IsEditing) {
    return;
  }

  const buttons = document.querySelectorAll('.product-add-to-cart .thule-btn-shop');
  buttons?.forEach((button) => {
    setupButton(button);
  });
});
